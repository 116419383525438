import React from 'react';

import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout fullMenu>
    <article id="main">
      <header>
        <h2>Política de privacidad</h2>
        <p>Tratamiento de datos</p>
      </header>
      <section className="wrapper style5">
        <div className="inner">
        <h2>AUTORIZACIONES</h2>
            <p>De manera expresa autorizo a JASCALLA S.A.S. a realizar las siguientes actuaciones:</p>
            <p style={{ textAlign: 'justify' }}>Consulta y reporte a operadores de bases de datos: La persona natural o jurídica autoriza de forma irrevocable y permanente a JASCALLA S.A.S.., para consultar ante cualquier operador de base de datos o central de riesgo, toda la información sobre el comportamiento crediticio y comercial de la empresa, hábitos de pago y cumplimiento de sus obligaciones. Así mismo, para que informe y reporte ante dichas entidades el comportamiento de pago, cumplimiento o incumplimiento de las obligaciones crediticias o de los deberes legales de contenido patrimonial, respecto a todas las operaciones que bajo cualquier modalidad haya celebrado o llegue a celebrar en el futuro con JASCALLA S.A.S.., o respecto de obligaciones a su cargo cuyo acreedor sea la entidad, de tal forma que aquellas entidades presenten una información veraz, pertinente, completa, actualizada y exacta de mi desempeño como deudor, codeudor o avalista.</p>
            <p style={{ textAlign: 'justify' }}>Autorizo que JASCALLA S.A.S.., consulte en cualquier lista restrictiva mi nombre y número de identificación.</p>
            <p style={{ textAlign: 'justify' }}><strong>Envío de información:</strong> Autorizo de forma irrevocable y expresa a JASCALLA S.A.S.., para enviar información relacionada con atención de los productos y servicios adquiridos, información comercial, legal, de seguridad, de servicios o de cualquier otra índole de manera telefónica y/o a través de mensajes a mi(s) celular(es) y a mi(s) correo(s) electrónico(s).</p>
            <p style={{ textAlign: 'justify' }}><strong>Grabación de llamadas:</strong> Autorizo de manera expresa e irrevocable a JASCALLA S.A.S.., a grabar y conservar en cintas magnetofónicas, registros digitales o en cualquier otro medio que considere idóneo para el almacenamiento de información, las conversaciones telefónicas entre la persona natural suscrita y JASCALLA S.A.S.., acerca de la negociación, acuerdo y ejecución de operaciones, transacciones, solicitudes, quejas y/o cualquier aspecto relacionado con la ejecución de productos y/o servicios por adquirir.</p>
            <p style={{ textAlign: 'justify' }}>La información aquí obtenida y recopilada, se sujetará a reserva en la forma prevista por la ley, por lo mismo, JASCALLA S.A.S.., estará autorizada para utilizarla con fines probatorios, de control, transparencia de operaciones, administración e información de calidad o cualquier otro propósito con fines correspondientes a las actividades que se empezarán a realizar.</p>
            <p style={{ textAlign: 'justify' }}><strong>Bloqueos Automáticos de Cuenta:</strong> En el evento de presentarse operaciones inusuales en cualquiera de las cuentas de la persona natural, se autoriza expresamente a JASCALLA S.A.S.., para bloquearlas. Se entienden como operaciones inusuales, aquellas que reflejan saldos y/o transacciones u operaciones inconsistentes con el movimiento normal de las cuentas y/o la actividad comercial suscrita.</p>
            <p style={{ textAlign: 'justify' }}><strong>Destrucción de Documentos:</strong> En el evento de que sea expulsado o se desvinculado de JASCALLA S.A.S.., autorizo expresamente a destruir toda la documentación aportada para el estudio de créditos, análisis y demás documentación como pagarés en blanco y la carta de instrucciones, si a ello hubiera lugar.</p>
            <p style={{ textAlign: 'justify' }}><strong>Aceptación de contratos y reglamentos:</strong> Conozco y acepto en su integridad el (los) reglamento(s) y contrato (s) que involucran la apertura del (los) producto(s) solicitado(s) y confirmo que he sido informado de la facultad de solicitar copia física del (los) mismo(s), así como la posibilidad de consultar en cualquier momento dicho(s) reglamento(s) y contrato(s) a través de la página web https://cosascripto.com/ manifiesto que se me ha informado que, en caso de que JASCALLA S.A.S.., realice alguna modificación a los textos de sus reglamentos y contratos, los mismos serán publicados y notificados en la página web de la entidad.</p>
            <p style={{ textAlign: 'justify' }}><strong>Verificación y actualizaciones de la información:</strong> Certifico que la información suministrada en esta solicitud y en los documentos adjuntos es exacta y verídica; por lo mismo, podrá ser verificada en cualquier momento por JASCALLA S.A.S.., con quien se obliga a actualizar anualmente la información aquí consignada.</p>
            <p style={{ textAlign: 'justify' }}>La persona natural exime a la entidad de toda responsabilidad que se derive por información errónea, falsa o inexacta que yo hubiere proporcionado en este documento o en los de actualización. Cualquier inexactitud en la información suministrada implicará la negación de la solicitud o la cancelación del producto, según sea el caso.</p>
            <h2>TRATAMIENTO DE INFORMACIÓN</h2>
            <p style={{ textAlign: 'justify' }}>Autorizo en todo tiempo a JASCALLA S.A.S..,: <br/>
              1) A utilizar los datos suministrados por mí en el presente documento a través de cualquier medio, para fines operativos, comerciales y de mercadeo, ofrecimiento de productos o servicios prestados por JASCALLA S.A.S.., o cualquiera de sus aliados comerciales nacionales o extranjeros. <br/>
              2) A compartir la información personal, comercial y financiera para que sea conocida y tratada por: <br/>
              <ul>
                <li>a) Terceros que en calidad de proveedores nacionales o extranjeros en el país o en el exterior, presten servicios tecnológicos, logísticos, operativos, de mercado de productos, de cobranza, de seguridad o de apoyo.<br/> </li>
                <li>
                  b) Autoridades nacionales o extranjeras en ejercicio de sus funciones.
                </li>
              </ul>
            </p>
            <p style={{ textAlign: 'justify' }}>Declaro expresamente que se me ha informado del derecho que tengo como titular de la información de conocer en cualquier momento los datos de mi titularidad, con los que cuenta JASCALLA S.A.S.., con el fin de actualizarlos, corregirlos o rectificarlos, así como de la posibilidad que tengo de solicitar su eliminación o revocación, en los casos previstos en la ley.</p>
            <p style={{ textAlign: 'justify' }}>Adicionalmente, declaro que he sido informado y conozco de las políticas y procedimientos de JASCALLA S.A.S.., para el manejo de información personal, los cuales podrán ser consultados de forma permanente en la en la página de internet <a href='https://cosascripto.com/'>cosascripto.com</a> </p>

            <h2>POLÍTICA DE CONTROL DE RIESGOS LA/FT</h2>
            <p style={{ textAlign: 'justify' }}>EL ASOCIADO y/o PROVEEDOR se obliga a cumplir con la política de prevención y control del riesgo de lavado de activos y financiación del terrorismo adoptada por JASCALLA S.A.S.., así como las normas y estándares internacionales que regulan la materia. En caso de tener conocimiento de una operación intentada o sospechosa, JASCALLA S.A.S.., lo reportará de forma inmediata a la UIAF (Unidad de información y análisis financiero) y dará por terminada la relación contractual entre las partes, sin que haya lugar al pago de ninguna indemnización a favor de EL ASOCIADO y/o PROVEEDOR.</p>

            <h2>DECLARACIÓN DE ORIGEN DE FONDOS</h2>
            <p style={{ textAlign: 'justify' }}>Aseguró que la información aquí relacionada es totalmente cierta, que actúo en nombre propio y de manera voluntaria, identificado con CC CE NIT NUIP ya referido y realizó la siguiente declaración de Origen de Fondos y Bienes Propios ante JASCALLA S.A.S. con el propósito de dar cumplimiento a las disposiciones legales establecidas en la normatividad vigente sobre el Sistema de Administración de Riesgo, Lavado de Activos y Financiación del Terrorismo (SARLAFT):</p>

            <p style={{ textAlign: 'justify' }}>Por lo anterior, bajo la gravedad del juramento, expreso voluntariamente que la información aportada es totalmente cierta y autorizo a JASCALLA S.A.S. para realizar las averiguaciones y validaciones que estime conveniente a fin de ratificar la veracidad de dicha información y reporte ante las autoridades competentes. En caso de que JASCALLA S.A.S. determine que dentro de la información aportada por mí existe inexactitud o algún tipo de falsedad, la eximo de cualquier responsabilidad y al mismo tiempo la autorizo para cancelar, si es del caso, mi vínculo comercial, así como también los contratos de productos y servicios que poseo actualmente.</p>

            <p style={{ textAlign: 'justify' }}>1. Los recursos o dineros que estoy invirtiendo, trasladando y/o transfiriendo , corresponden a las actividades ya descritas.</p>

            <p style={{ textAlign: 'justify' }}>2. Declaró que tales recursos no están derivados o vinculados con ningún tipo de actividades de tipo ilícito.</p>

            <p style={{ textAlign: 'justify' }}>3. También informó que ningún tercero está autorizado para realizar inversiones, pagos o y/o transferencias a mi nombre o dineros relacionados con actividades ilícitas y que tampoco realizare transacciones vinculadas con este tipo de actividades que impliquen riesgo de lavado de activos o financiación del terrorismo o en favor de personas relacionadas con ellas.</p>
        </div>
      </section>
    </article>
  </Layout>
);

export default IndexPage;
